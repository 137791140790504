import { gql } from "graphql-request";

const NOT_FOUND_FRAGMENT = gql`
  fragment NotFoundFragment on NotFound {
    message
  }
`;

const PRODUCT_FRAGMENT = gql`
  fragment WProductFragment on WProduct {
    __typename
    id
    owner {
      __typename
      id
    }
    name
    description
    short_description
    slug
    date_created_gmt
    date_modified_gmt
    type
    is_r18
    variations {
      __typename
      id
      description
      price
      regular_price
      sale_price
      date_on_sale_from_gmt
      date_on_sale_to_gmt
      stock_quantity
      stock_status
    }
    stock_quantity
    stock_status
    status
    featured
    price
    price_text
    regular_price
    sale_price
    date_on_sale_from_gmt
    date_on_sale_to_gmt
    purchasable
    stock_status
    virtual
    distributor_service
    digitalAssetAuthorized
    digitalAssets {
      __typename
      id
      key
      lastModified
      size
    }
    categories {
      __typename
      id
      name
    }
    tags {
      __typename
      id
      name
      slug
      description
    }
    main_image
    images
    media {
      type
      srcSet
      thumbnail
    }
    menu_order
    shipping {
      method
      region
      shippingFeeFirst
      shippingFeeAdditional
    }
  }
`;

const PRODUCT_SUMMARY_FRAGMENT = gql`
  fragment WProductFragment0d4f on WProduct {
    __typename
    id
    status
    name
    virtual
    distributor_service
    is_r18
    short_description
    price_text
    main_image
    images
    sale_price
    regular_price
    type
    stock_status
    variations {
      __typename
      id
      sale_price
      regular_price
    }
    categories {
      __typename
      id
      name
    }
    tags {
      __typename
      id
      name
    }
  }
`;

const SHOP_SUMMARY_FRAGMENT = gql`
  fragment WShopFragment166f on WShop {
    id
    name
    main_image
    banner_image
  }
`;

const PRODUCT_SUMMARY_FRAGMENT_WITH_SHOP = gql`
  ${NOT_FOUND_FRAGMENT}
  ${PRODUCT_SUMMARY_FRAGMENT}
  ${SHOP_SUMMARY_FRAGMENT}
  fragment WProductFragmentc4e0 on WProduct {
    ...WProductFragment0d4f
    shop {
      ...NotFoundFragment
      ...WShopFragment166f
    }
  }
`;

export {
  NOT_FOUND_FRAGMENT,
  PRODUCT_FRAGMENT,
  PRODUCT_SUMMARY_FRAGMENT,
  PRODUCT_SUMMARY_FRAGMENT_WITH_SHOP,
  SHOP_SUMMARY_FRAGMENT,
};

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import getConfig from "next/config";

import AlgoliaSearchInsight from "search-insights";
import { v4 } from "uuid";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const NEXT_PUBLIC_ALGOLIA_APPLICATION_ID =
  publicRuntimeConfig?.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ??
  serverRuntimeConfig?.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ??
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID;

const NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY =
  publicRuntimeConfig?.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ??
  serverRuntimeConfig?.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ??
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY;

const ALGOLIA_UUID = "algolia-uuid";

const AlgoliaContext = createContext({ searchInsight: null });

export const AlgoliaProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const uuid = localStorage.getItem(ALGOLIA_UUID) ?? v4();
    localStorage.setItem(ALGOLIA_UUID, uuid);
    AlgoliaSearchInsight("init", {
      appId: NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
      apiKey: NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
      region: "us",
    });
    AlgoliaSearchInsight("setUserToken", `anonymous-${uuid}`);
    setInitialized(true);
  }, []);

  return (
    <AlgoliaContext.Provider
      value={useMemo(
        () => ({
          searchInsight: initialized ? AlgoliaSearchInsight : null,
        }),
        [initialized]
      )}
    >
      {children}
    </AlgoliaContext.Provider>
  );
};

export const useAlgolia = () => useContext(AlgoliaContext);

export const withAlgolia = (Component) => (props) =>
  (
    <AlgoliaContext.Consumer>
      {(ctx) => <Component {...props} {...ctx} />}
    </AlgoliaContext.Consumer>
  );

export default AlgoliaContext;

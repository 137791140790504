import React, { useMemo, useRef } from "react";

import Image from "next/image";

import { Box, Typography } from "@mui/material";

import map from "lodash/map";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import useBreakpointValue from "../../../../hooks/useBreakpointValue";
import ImageCarouselItem from "../ImageCarouselItem";

const ImageCarouseThumbnail = ({
  src,
  date,
  description,
  maxLines = {},
  sx,
  labelSx,
  ...props
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: { xs: "column", md: "row" },

      gap: { xs: "1rem", md: "2%" },

      ...sx,
    }}
    {...props}
  >
    <Box
      sx={{
        position: "relative",
        width: { xs: "100%", md: "45%" },
        filter: {
          xs: "drop-shadow(-8px 8px 1px #0000002f)",
          md: "drop-shadow(-12px 12px 1px #0000002f)",
        },
      }}
    >
      <Image src={src} layout="responsive" />
    </Box>
    <Box
      sx={{
        paddingY: { xs: 0, md: "1%" },
        // For Line Clamp
        flex: 1,
        overflow: "hidden",
        // Font Style
        color: "white",
        textShadow: "-4px 4px 2px #0000002f",
        ...labelSx,
      }}
    >
      <Typography
        align="left"
        sx={{
          // Line Clamp
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: "vertical",

          whiteSpace: "pre-line",
          wordBreak: "break-all",
          msWordBreak: "break-all",
          fontSize: "1em",
        }}
      >
        <span style={{ display: "block", fontSize: "0.75em" }}>{date}</span>
        {description}
      </Typography>
    </Box>
  </Box>
);

const ImageCarousel = ({ items, carouselProps, sx, ...props }) => {
  const imageGallary = useRef();

  const stopAutoPlay = useMemo(() => {
    imageGallary.current?.pause();
  }, [imageGallary]);
  const startAutoPlay = useMemo(() => {
    imageGallary.current?.play();
  }, [imageGallary]);

  const multipleItems = Object.keys(items).length > 1;

  const carouselConfig = useBreakpointValue({
    md: {
      showThumbnails: true,
      thumbnailScrollY: true,
      thumbnailPosition: "right",
      thumbnailScrollbarStyle: {
        overflowY: "auto",
        overflowX: "visible",

        scrollbarColor: `#83fff2 transparent`,
        scrollbarWidth: "thin",
        // Webkit
        "&::-webkit-scrollbar": {
          width: "0.3rem",
          height: "0.3rem",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
          marginY: { xs: 0, md: "1rem" },
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#83fff2",
          borderRadius: "1rem",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#83fff29f",
        },
      },
    },
    xs: {
      showThumbnails: multipleItems,
      thumbnailScrollY: false,
      thumbnailPosition: "bottom",
      thumbnailScrollbarStyle: {
        overflowY: "visible",
        overflowX: "auto",

        // Hide Scrollbar
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  });

  const carouselItems = map(items, ({ src, href, date, organizer, title }) => ({
    original: src,
    thumbnail: src, // resize?
    href,
    date,
    organizer,
    title,
  }));

  return (
    <Box
      sx={{
        marginY: "1rem",
        "& div[class~=image-gallery-slide-wrapper]": {
          width: { xs: "100%", md: "55%" }, // ratio
          "& div[class~=image-gallery-slides]": {
            // image border
            border: "0.25rem solid #83fff2",
            boxShadow: "0 0 1.5rem #83fff2",
            borderRadius: "0.4rem",
            overflow: "hidden",
          },
        },
        "& div[class~=image-gallery-thumbnails-wrapper]": {
          margin: 0,
          width: { xs: "100%", md: "45%" }, // ratio

          "& div[class~=image-gallery-thumbnails]": {
            marginLeft: { xs: 0, md: "1rem" },
            marginTop: { xs: "1.5%", md: 0 },
            paddingY: "0.75rem",
            paddingX: { xs: 0, md: "0.5rem" },
            backgroundColor: { xs: "transparent", md: "#0000003f" },
            borderRadius: "1rem",

            "& > nav[class~=image-gallery-thumbnails-container]": {
              // thumbnail container height
              height: { xs: "auto", md: "100%" },
              paddingX: "0.5rem",
              cursor: "auto",

              // Scrollbar Sytle
              ...carouselConfig.thumbnailScrollbarStyle,

              "& > button[class~=image-gallery-thumbnail]": {
                width: { xs: "40%", md: "100%" },
                margin: 0,
                marginBottom: { xs: 0, md: "1rem" }, // padding?

                "&:not(:last-child)": {
                  marginRight: { xs: "4%", md: 0 }, // padding & remove last-child tag?
                },
                "&:not(.active)": {
                  cursor: "pointer",
                },
                // Disable border
                border: "none",

                // Hover effect
                opacity: 0.85,
                transition: "opacity 0.3s",
                "&.active, :hover": {
                  opacity: 1,
                },
              },
            },
          },
        },
      }}
      {...props}
    >
      <ImageGallery
        ref={imageGallary}
        items={carouselItems}
        renderItem={(item) => (
          <ImageCarouselItem
            src={item.original}
            href={item.href}
            label={[item.organizer, item.title]
              .filter((value) => value && value?.length > 0)
              .join("  ")}
          />
        )}
        lazyLoad
        infinite
        // Autoplay
        autoPlay={process.env.NODE_ENV === "production"}
        onMouseLeave={startAutoPlay}
        onMouseOver={stopAutoPlay}
        onTouchStart={stopAutoPlay}
        onThumbnailClick={stopAutoPlay}
        slideInterval={7000}
        // Thumbnails
        showThumbnails={carouselConfig.showThumbnails}
        thumbnailPosition={carouselConfig.thumbnailPosition}
        renderThumbInner={(item) => (
          <ImageCarouseThumbnail
            src={item.thumbnail}
            date={item.date}
            description={[item.organizer, item.title]
              .filter((value) => value && value?.length > 0)
              .join("\n")}
            maxLines={{ xs: 3, sm: 4, md: 3, lg: 4, xl: 5 }}
            labelSx={{
              fontSize: {
                xs: "0.85rem",
                md: "1rem",
                lg: "1.15rem",
              },
            }}
          />
        )}
        disableThumbnailScroll
        // Disable controls
        disableSwipe
        disableThumbnailSwipe
        showFullscreenButton={false}
        showNav={false}
        showPlayButton={false}
        useBrowserFullscreen={false}
      />
    </Box>
  );
};

export default ImageCarousel;

import { React } from "react";

import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  paddingTop: 48,
}));

const MainLayout = ({ children, headerSx, contentSx }) => (
  <MainLayoutRoot>
    <MainNavbar
      sx={{
        color: "white",
        backgroundColor: "black",
        "& button, a, p": {
          color: "white",
        },
        ...headerSx,
      }}
    />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",

        "& footer": {
          backgroundColor: "#aa87cc",
          color: "white",
          "& button, a, p": {
            color: "white",
          },
        },

        ...contentSx,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
      <Footer sx={{ flexShrink: 0 }} />
    </Box>
  </MainLayoutRoot>
);

export default MainLayout;

import React from "react";

import Image from "next/image";
import Link from "next/link";

import { Box, Grid, Link as MuiLink } from "@mui/material";

import { map } from "lodash";

import newsBackground from "../../../../../public/vtuber/event-deco-2.svg";
import newsIcon from "../../../../../public/vtuber/news-star.svg";
import newsTitle from "../../../../../public/vtuber/news-title.svg";

const announcementGridSize = {
  xs: 12,
  sm: 6,
  md: 4,
};

const Announcement = ({ items, fontSize = "1rem", sx }) => (
  <Grid
    container
    sx={{
      position: "relative",
      display: "flex",
      padding: 1,
      backgroundColor: "#000000",

      // Background Decoration
      overflow: "hidden",
      "&::before": {
        display: "block",
        content: `url(${newsBackground.src})`,
        opacity: "0.7",
        // Positon
        position: "absolute",
        transform: "translate(50%,-50%) rotate(-25deg)",
        top: "5%",
        right: "10%",

        // Size
        width: "50%",
        minWidth: "256px",
      },
      ...sx,
    }}
  >
    <Grid
      item
      xs={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingX: "0.5rem",
      }}
    >
      <Box position="relative" sx={{ width: "100%", height: "2rem" }}>
        <Image src={newsTitle} layout="fill" objectFit="contain" />
      </Box>
    </Grid>
    <Grid
      item
      container
      xs={10}
      columnSpacing={2}
      sx={{ position: "relative" }}
    >
      {map(items, ({ label, href }, index) => (
        <Grid
          item
          key={index}
          display="flex"
          alignItems="center"
          justifyContent="left"
          {...announcementGridSize}
          sx={{
            columnGap: "1rem",
            width: "100%", // for texOverflow
          }}
        >
          <Box
            position="relative"
            sx={{
              width: fontSize,
              height: fontSize,
              minWidth: fontSize,
              minHeight: fontSize,
            }}
          >
            <Image src={newsIcon} layout="fill" />
          </Box>
          <Link href={href} passHref>
            <MuiLink
              underline="hover"
              sx={{
                fontSize,
                color: "#999bff",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {label}
            </MuiLink>
          </Link>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default Announcement;

import React from "react";

import Image from "next/image";
import Link from "next/link";

import {
  AppBar,
  Box,
  Divider,
  Link as MuiLink,
  Toolbar,
  Typography,
} from "@mui/material";

import VtuberLogo from "../../../public/vtuber/vtuber-title.svg";
import Logo from "../Logo";

const MainNavbar = ({ sx }) => (
  <AppBar
    elevation={0}
    sx={{
      backgroundColor: "background.paper",
      color: "text.secondary",
      ...sx,
    }}
  >
    <Toolbar variant="dense">
      <Logo sx={{ mr: 1 }} />
      <Link href="/" passHref>
        <MuiLink
          color="textPrimary"
          noWrap
          underline="none"
          variant="subtitle1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          飛天奶茶
          <Typography
            display="inline"
            variant="inherit"
            sx={{
              color: "#83fff2 !important",
              lineHeight: 1,
              fontWeight: "800",
              overflow: "hidden",
            }}
          >
            &nbsp;|&nbsp;
          </Typography>
          <Image src={VtuberLogo} height={32} />
        </MuiLink>
      </Link>
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
    <Divider />
  </AppBar>
);
export default MainNavbar;

import React from "react";

import Image from "next/image";

import { Box, Button, IconButton, Typography } from "@mui/material";

import { APP_ROUTES } from "market-webapp-commons";

import EventBackground from "../../../public/vtuber/event-deco-1.svg";
import EventTitle from "../../../public/vtuber/event-title.svg";
import ShopButtonIcon from "../../../public/vtuber/shop-button-icon.svg";
import ShopBackgroundTop from "../../../public/vtuber/shop-deco-1.svg";
import ShopBackgroundBottom from "../../../public/vtuber/shop-deco-2.svg";
import ShopTitle from "../../../public/vtuber/shop-title.svg";
import ItemGrid from "../../components/catalog/ItemGrid";
import ShopSummaryItem from "../../components/catalog/ShopSummaryItem";
import { MAIN_MARKET_DOMAIN_NAME } from "../../constants";
import useBreakpointValue from "../../hooks/useBreakpointValue";
import Announcement from "./components/Announcement";
import Heading from "./components/Heading";
import ImageCarousel from "./components/ImageCarousel";

const Section = ({ children, sx }) => (
  <Box
    sx={{
      position: "relative",
      paddingX: "5%",
      paddingY: "2.5rem",
      ...sx,
    }}
  >
    {children}
  </Box>
);

const MoreButton = ({ label, href, sx }) => (
  <Button
    variant="text"
    disableRipple
    href={href}
    sx={{
      paddingX: "1rem",
      alignItems: "center",

      borderRadius: "4rem",
      backgroundColor: "#BB93E4",
      "&:hover": {
        backgroundColor: "#BB93E4",
      },
      ...sx,
    }}
  >
    <Typography
      sx={{
        color: "#83FFF2",
        display: "inline",
        fontSize: "1.25rem",
        textShadow: "0 0 4px #83fff2",
        lineHeight: "100%",
      }}
    >
      {label}
    </Typography>
    <Box
      sx={{
        position: "relative",
        height: "1.5rem",
        width: "1.5rem",
        filter: "drop-shadow(0px 0px 4px #83fff2)",
      }}
    >
      <Image src={ShopButtonIcon} layout="fill" />
    </Box>
  </Button>
);

const Decoration = ({ beforeProps }) => (
  <Box
    sx={{
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      overflow: "hidden",

      "&::before": {
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        ...beforeProps,
      },
    }}
  />
);

const ImageHeading = ({ src, sx }) => (
  <Box
    sx={{
      position: "relative",
      width: { xs: "65%", sm: "50%" },
      height: { xs: "4rem", sm: "4rem", md: "5rem", lg: "6rem" },
      ...sx,
    }}
  >
    <Image src={src} layout="fill" objectPosition="left" objectFit="contain" />
  </Box>
);

const VtubersView = ({
  carouselBanners,
  shops,
  announcements,
  onMouseDown,
}) => {
  const displayedShops = useBreakpointValue({
    xs: 24,
  });

  return (
    <>
      <Box
        sx={{
          position: "relative",
          // paddingBottom: { xs: 0, sm: "2rem", md: "3rem" },
          backgroundImage: `radial-gradient(circle at 0 0, #0000009f, transparent 35%), linear-gradient(#5869ae 40%, #b38bd0)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Decoration
          beforeProps={{
            content: `url('${EventBackground.src}')`,
            transform: "translate(-50%,-50%) rotate(90deg)",
            marginTop: "10%",
            marginLeft: "20%",
            width: "65%",
            minWidth: "512px",
            opacity: "0.5",
          }}
        />
        <Box sx={{ padding: { xs: 2, sm: 4, md: 8 } }}>
          <ImageHeading
            src={EventTitle}
            sx={{
              marginX: "1%",
              width: "auto",
              height: { xs: "2rem", sm: "2.5rem", md: "2rem", lg: "2.5rem" },
            }}
          />
          <ImageCarousel items={carouselBanners} />
        </Box>
        {announcements && (
          <Announcement
            items={announcements}
            sx={{ paddingBottom: { xs: 2, md: 1 } }}
          />
        )}
      </Box>
      <Section
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "black",
          borderTop: "0.25rem solid #f576bd",
          borderBottom: "0.25rem solid #f576bd",
          outline: "3px solid #ffffff",
          boxShadow: "0 0 4px 4px #83fff2",

          position: "relative",
          zIndex: 1,
        }}
      >
        <Decoration
          beforeProps={{
            content: `url('${ShopBackgroundTop.src}')`,
            transform: "translate(-50%,-50%)",
            left: "52.5%",
            width: "25%",
            minWidth: "128px",
            opacity: "0.95",
          }}
        />
        <Decoration
          beforeProps={{
            content: `url('${ShopBackgroundBottom.src}')`,
            transform: "translate(50%,50%)",
            top: "auto",
            left: "auto",
            right: "5%",
            bottom: "15%",
            width: "40%",
            opacity: "0.95",
          }}
        />
        <Box sx={{ width: { xs: "100%", md: "calc(100% - 4rem)" } }}>
          <Box
            sx={{
              display: "flex",
              columnGap: "1rem",
              paddingY: "0.5rem",
              alignItems: { xs: "center", md: "end" },
            }}
          >
            <ImageHeading src={ShopTitle} />
            <Box sx={{ flex: 1 }} />
            <Heading
              fontSize={{
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              }}
              color="white"
              shadowColor="#FFFF00"
              strokeColor="#FFFF00"
              href={`${MAIN_MARKET_DOMAIN_NAME}${APP_ROUTES.DASHBOARD_INIT}`} //! external url
              label="開設攤位"
              sx={{
                WebkitTextStrokeWidth: "0.5px",
                width: { xs: "2.5rem", sm: "3.5rem", md: "7.5rem" }, // TODO: not hardcode, Firefox has bigger font
              }}
            />
            <Heading
              fontSize={{
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              }}
              color="white"
              shadowColor="#FF69B4"
              strokeColor="#FF69B4"
              href={`${MAIN_MARKET_DOMAIN_NAME}${APP_ROUTES.STATIC_WORKFLOW}`} //! external url
              label="交易流程"
              sx={{
                WebkitTextStrokeWidth: "0.5px",
                width: { xs: "2.5rem", sm: "3.5rem", md: "7.5rem" },
              }}
            />
          </Box>
          <Box sx={{ position: "relative" }}>
            <ItemGrid
              component={ShopSummaryItem}
              items={shops.slice(0, displayedShops)}
              onMouseDown={(object) =>
                onMouseDown?.({ ...object, section: "shops" })
              }
              columnSpacing={1}
              rowSpacing={1}
              gridItemProps={{
                xs: 6,
                sm: 4,
                md: 3,
                lg: 3,
                sx: {
                  "& > div[class~=MuiCard-root]": {
                    border: "2px solid yellow",
                    boxShadow: "0px 0px 10px yellow",
                  },
                },
              }}
              componentProps={{ urlPrefix: MAIN_MARKET_DOMAIN_NAME }}
              sx={{ paddingLeft: "3%" }}
            />
          </Box>
        </Box>
      </Section>
    </>
  );
};

export default VtubersView;

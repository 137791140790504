import React from "react";

import Image from "next/image";
import Link from "next/link";

import { Box, Link as MuiLink } from "@mui/material";

import LogoImage from "../../public/apple-touch-icons.ico";
import { MAIN_MARKET_DOMAIN_NAME } from "../constants";

const Logo = ({ sx, ...props }) => (
  <Box
    sx={{
      borderRadius: "50%",
      height: 32,
      overflow: "hidden",
      width: 32,
      ...sx,
    }}
    {...props}
  >
    <Link href={MAIN_MARKET_DOMAIN_NAME} passHref>
      <MuiLink sx={{ lineHeight: 1 }}>
        <Image alt="飛天奶茶" height={32} width={32} src={LogoImage} priority />
      </MuiLink>
    </Link>
  </Box>
);

export default Logo;

import React, { memo } from "react";

import Image from "next/image";
import Link from "next/link";

import { Box, Link as MuiLink, Typography } from "@mui/material";

import isEqual from "lodash/isEqual";

const MainCarouselItem = ({ src, href, label, onLoadingComplete, sx }) =>
  href ? (
    <Link href={href} passHref>
      <MuiLink underline="none">
        <Box
          sx={{
            position: "relative",
            "& > div:last-of-type": {
              transition: "opacity 1s",
            },
            "&:hover": {
              "& > div:last-of-type": {
                opacity: 0,
                transitionDelay: "1.5s",
              },
            },
            ...sx,
          }}
        >
          <Image
            src={src}
            layout="responsive"
            priority
            onLoadingComplete={onLoadingComplete}
          />
          <Box
            sx={{
              position: "absolute",
              left: 0,
              bottom: 0,

              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              paddingX: "5%",

              width: "100%",
              height: "15%",

              backgroundImage: "linear-gradient(to bottom, transparent, black)",
            }}
          >
            <Typography
              color="primary.contrastText"
              noWrap
              fontSize={{
                xs: "0.8rem",
                sm: "1.15rem",
                md: "1rem",
                lg: "1.25rem",
              }}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      </MuiLink>
    </Link>
  ) : (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <Image src={src} layout="responsive" priority />
    </Box>
  );

export default memo(MainCarouselItem, isEqual);

import React, { useCallback, useMemo } from "react";

import getConfig from "next/config";

import { gql } from "graphql-request";

import filter from "lodash/filter";
import isUndefined from "lodash/isUndefined";
import mapValues from "lodash/mapValues";
import omitBy from "lodash/omitBy";
import shuffle from "lodash/shuffle";
import sortBy from "lodash/sortBy";
import { APP_PATHS, APP_ROUTES } from "market-webapp-commons";
import { NextSeo } from "next-seo";

import njsjOnlyHKEventImg from "../../public/banners/2434_only_hk_banner.jpg";
import myaEventImg from "../../public/banners/mya_3d_banner.jpg";
import vtuberOnlyEventImg from "../../public/banners/vtuber_only_banner.jpg";
import TrackPage from "../components/TrackPage";
import { useAlgolia } from "../contexts/AlgoliaContext";
import GraphQLClient from "../graphql-client";
import { PRODUCT_SUMMARY_FRAGMENT_WITH_SHOP } from "../graphql/fragments";
import MainLayout from "../layout/MainLayout";
import { SeoBaselineConfig } from "../seo";
import VtubersView from "../views/VtubersView";

const { serverRuntimeConfig } = getConfig();

const CAROUSEL_BANNERS = {
  myaEvent: {
    src: myaEventImg,
    href: "/event/mya",
    date: "28-10-2022",
    organizer: "",
    title: "來自香港的人氣Vtuber——米亞Mya，籌備已久的3D化即將完成﹗",
  },
  mya3dProduct: {
    src: myaEventImg,
    href: "/event/mya-3d-product",
    date: "02-11-2022",
    organizer: "",
    title: "3D化的紀念商品套裝開放預購啦！",
  },
  njsjOnlyHKEvent: {
    src: njsjOnlyHKEventImg,
    href: "/event/2434-only-hk",
    date: "28-10-2022",
    organizer: "",
    title: "2434 Only HK 將於11月6號舉辦🎉",
  },
  vtuberOnlyEvent: {
    src: vtuberOnlyEventImg,
    href: "/event/vtuber-only",
    date: "28-10-2022",
    organizer: "",
    title: "《VTUBER-ONLY》虛擬實況主同人誌販售交流會將於國立臺灣師範大學舉辦",
  },
};

const CAROUSELS = [
  {
    id: "myaEvent",
    priority: 0,
  },
  {
    id: "njsjOnlyHKEvent",
    priority: 1,
  },
  {
    id: "vtuberOnlyEvent",
    priority: 2,
  },
  {
    id: "mya3dProduct",
    priority: 3,
  },
];

const ANNOUNCEMENTS = [
  // { label: "444444", href: "/news" },
];

const SHOP_INCLUDES = [
  113246, 121270, 117388, 108500, 113469, 115146, 98626, 125799, 121902, 126442,
  117862, 111827, 112961, 125207, 100214, 122566, 117144,
];

const GET_VTUBERS_PAGE_GQL = gql`
  query MainViewGetMainPage($shopInclude: [ID!]) {
    woocommerce {
      shops(
        filter: { include: $shopInclude, per_page: 24, orderby: "popularity" }
        maxAge: 300
      ) {
        ... on WShop {
          id
          main_image
          banner_image
          name
        }
      }
    }
  }
`;

export const getServerSideProps = async ({ res }) => {
  try {
    const client = GraphQLClient;

    const resp = await client.request(GET_VTUBERS_PAGE_GQL, {
      zone: "default",
      shopInclude: SHOP_INCLUDES,
    });

    res.setHeader("cache-control", "public, max-age=3600");
    return {
      props: omitBy(
        {
          data: {
            carousels: sortBy(
              filter(
                CAROUSELS,
                (x) =>
                  (!x.startDate || Date.now() > x.startDate) &&
                  (!x.endDate || x.endDate > Date.now())
              ),
              (x) => -x.priority
            ),
            shops: shuffle(resp.woocommerce.shops),
            announcements: ANNOUNCEMENTS.length > 0 ? ANNOUNCEMENTS : null,
          },
        },
        isUndefined
      ),
    };
  } catch (err) {
    //* runs on server
    // eslint-disable-next-line no-console
    console.info(err);
    res.setHeader(
      "cache-control",
      `public, max-age=${serverRuntimeConfig.NEXT_PUBLIC_PAGE_ERROR_CACHE_SECONDS}`
    );
    return {
      notFound: true,
    };
  }
};

export default function Page({ data }) {
  const { carousels, shops, announcements } = data;
  const seoTitle = "飛天奶茶Vtuber區";
  const titleTemplate = "%s";
  const canonical = `${SeoBaselineConfig.canonical}${APP_ROUTES.HOME}`;

  const carouselBanners = useMemo(
    () => mapValues(carousels, (x) => CAROUSEL_BANNERS[x.id]),
    [carousels]
  );

  const { searchInsight } = useAlgolia();
  const mouseDownAnalytics = useCallback(
    (object) => {
      const { __typename, id, position, section } = object;
      const target = data[section];
      if (!target) return;

      const { hitsPerPage, page: p, queryId } = target;

      searchInsight?.("clickedObjectIDsAfterSearch", {
        index: __typename === "WProduct" ? "product-search" : "shop-search",
        eventName: `${APP_PATHS.HOME}#click`,
        objectIDs: [id],
        queryID: queryId,
        positions: [p * hitsPerPage + position],
      });
    },
    [data, searchInsight]
  );

  return (
    <>
      <TrackPage intent={APP_PATHS.HOME} />
      <NextSeo
        {...SeoBaselineConfig}
        titleTemplate={titleTemplate}
        title={seoTitle}
        canonical={canonical}
        openGraph={{
          ...SeoBaselineConfig.openGraph,
          title: seoTitle,
          url: canonical,
        }}
      />
      <VtubersView
        carouselBanners={carouselBanners}
        onMouseDown={mouseDownAnalytics}
        shops={shops}
        announcements={announcements}
      />
    </>
  );
}

Page.getLayout = function getLayout(page) {
  return <MainLayout>{page}</MainLayout>;
};

import React from "react";

import { Box, Link as MuiLink, Typography } from "@mui/material";

const Heading = ({
  href,
  label,
  fontSize,
  color,
  strokeColor,
  shadowColor,
  sx,
}) => (
  <Box sx={{ display: "", position: "relative", fontSize }}>
    <MuiLink underline="none" href={href}>
      <Typography
        align="center"
        sx={{
          position: "relative",
          fontSize: "1em",
          fontWeight: "700",
          color,
          WebkitTextStrokeColor: strokeColor,
          WebkitTextStrokeWidth: "1.5px",
          // Text shadow
          transition: "text-shadow .2s",
          textShadow: `0px 0px 24px ${shadowColor}, 0px 0px 24px ${shadowColor}3f`,
          "&:hover": {
            textShadow: `0px 0px 24px ${shadowColor}, 0px 0px 24px ${shadowColor}`,
          },
          // whiteSpace: "pre",
          ...sx,
        }}
      >
        {label}
      </Typography>
    </MuiLink>
  </Box>
);

export default Heading;
